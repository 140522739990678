import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static get targets() {
    return [];
  }

  connect() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const propertiesSection = urlParams.get("properties_section");
    if (propertiesSection) {
      const sectionId = `#preview-section-${propertiesSection}`;
      const section = document.querySelector(sectionId);
      if (section) {
        section.scrollIntoView();
      } else {
        console.warn(
          `Wanted to scroll the page but could not find '${sectionId}.'`
        );
      }
    }
  }

  disconnect() {}
}
