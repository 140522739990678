import pako from 'pako';

export default function decodeHtml(data) {
  // Decode base64 (convert ascii to binary)
  let strData = atob(data);
  // Convert binary string to character-number array
  const charData = strData.split('').map(function(x){return x.charCodeAt(0);});
  // Turn number array into byte-array
  const binData = new Uint8Array(charData);
  // Pako inflate binary array
  const inflatedData = pako.inflate(binData);
  // Convert gunzipped byteArray to utf-8 string:
  strData = new TextDecoder("utf-8").decode(inflatedData);
  strData = strData.trim();

  return strData;
}
