// Taken from: https://gist.github.com/yunusemredilber/c0598e168ef643fa8e9876b78c447b91
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  // static targets = ["input"];

  connect() {
    this.initialHeight = this.element.style.height;
    this.element.style.resize = 'none';
    // this.element.style.minHeight = `${this.element.scrollHeight}px`;
    this.element.style.overflow = 'hidden';
  }

  resize(e) {
    this.element.style.height = '5px';
    this.element.style.height = `${e.target.scrollHeight}px`;
  }

  reset() {
    this.element.style.height = this.initialHeight;
  }
}
