import { Controller } from "@hotwired/stimulus";
import Rails from "@rails/ujs";

export default class extends Controller {
  static get targets() {
    return ["form", "placeholder", "template", "item", "deleteAttribute"];
  }

  connect() {
    this.index = this.itemTargets.length;
  }

  add(e) {
    e.preventDefault();
    var content = this.templateTarget.innerHTML.replace(
      /TEMPLATE_INDEX/g,
      this.index
    );
    this.placeholderTarget.insertAdjacentHTML("beforebegin", content);
    this.index += 1;
  }

  remove(e) {
    e.preventDefault();
    const item = document.querySelector(`#${e.target.dataset.removeId}`);
    if (item) {
      item.remove();
      this.deleteAttributeTarget.value = e.target.dataset.removeAttributeName;

      Rails.fire(this.formTarget, "submit");
    } else {
      console.error(`item '#${e.target.dataset.removeId}' not found`);
    }
  }

  removeInPlace(e) {
    e.preventDefault();
    const item = document.querySelector(`#${e.target.dataset.removeId}`);
    if (item) {
      const destroyAttribute = document.querySelector(`#${item.id}__destroy`);

      if (destroyAttribute) {
        destroyAttribute.value = "1";
        item.remove();
      } else {
        console.error(`item '#${item.id}' does not have destroy hidden field`);
      }
    } else {
      console.error(`item '#${e.target.dataset.removeId}' not found`);
    }
  }

  removeInDOM(e) {
    e.preventDefault();
    const item = document.querySelector(`#${e.target.dataset.removeId}`);
    if (item) {
      item.remove();
    } else {
      console.error(`item '#${e.target.dataset.removeId}' not found`);
    }
  }

  disconnect() {}
}
