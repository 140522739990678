import { Controller } from "@hotwired/stimulus";

// TODO: figure out which selectors are better:
// const FOCUSABLE_SELECTORS = 'a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), iframe, object, embed, *[tabindex], *[contenteditable]';
const focusableElements =
  'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';

export default class extends Controller {
  static get targets() {
    return ["title", "description", "content"];
  }

  connect() {
    if (this.data.get("initialize") === "1") {
      this.open();
    }
  }

  disconnect() {}

  externalTriggerOpen(e) {
    this.open();
  }

  externalTriggerClose(e) {
    this.close();
  }

  // TODO: styling - https://css-tricks.com/considerations-styling-modal/
  open() {
    document.body.classList.add("modal--open");
    this.element.style.display = "";

    // TODO: focus the elements inside the modal AGAIN if the content was morphed
    this.trapTab();
  }

  close() {
    const modalIsActive = document.body.classList.contains("modal--open");

    if (modalIsActive) {
      this.element.style.display = "none";
      document.body.classList.remove("modal--open");
    }

    if (this.titleTarget) {
      this.titleTarget.innerHTML = "";
    }

    if (this.descriptionTarget) {
      this.descriptionTarget.innerHTML = "";
    }

    if (this.contentTarget) {
      this.contentTarget.innerHTML = "loading...";
    }

    // TODO: clear content?

    this.untrapTab();
    // TODO: focus the same element as before?
  }

  trapTab() {
    document.addEventListener("keydown", this.tabToNextElement.bind(this));

    const firstFocusableElement =
      this.element.querySelectorAll(focusableElements)[0];
    if (firstFocusableElement) {
      firstFocusableElement.focus();
    }
  }

  untrapTab() {
    document.removeEventListener("keydown", this.tabToNextElement.bind(this));
  }

  tabToNextElement(e) {
    let isTabPressed = e.key === "Tab" || e.keyCode === 9;

    if (!isTabPressed) {
      return;
    }

    const firstFocusableElement =
      this.element.querySelectorAll(focusableElements)[0];
    const focusableContent = this.element.querySelectorAll(focusableElements);
    const lastFocusableElement = focusableContent[focusableContent.length - 1];

    if (e.shiftKey) {
      if (document.activeElement === firstFocusableElement) {
        lastFocusableElement.focus();
        e.preventDefault();
      }
    } else {
      if (document.activeElement === lastFocusableElement) {
        firstFocusableElement.focus();
        e.preventDefault();
      }
    }
  }
}
