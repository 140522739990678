import { Controller } from "@hotwired/stimulus";
import lozad from "lozad";

export default class extends Controller {
  static get targets() {
    return [];
  }

  connect() {
    this.element[this.identifier] = this;

    const options = {
      threshold: [0, 0.2, 0.4, 0.6, 0.8, 1],
      enableAutoReload: false,
    };

    if (this.data.get("rootMargin")) {
      options["rootMargin"] = this.data.get("rootMargin");
    }

    // it will reload the new image when validating attributes changes
    if (this.data.get("autoReload") === "true") {
      options.enableAutoReload = true;
    }

    this.observer = lozad(this.element, options);

    this.observer.observe();
  }

  disconnect() {
    if (this.observer && this.observer.observer) {
      this.observer.observer.disconnect();
    }
  }
}
