import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static get targets() {
    return [];
  }

  initialize() {
    this.intersectionObserver = new IntersectionObserver((entries) =>
      this.processIntersectionEntries(entries)
    );
  }

  connect() {
    this.intersectionObserver.observe(this.element);
  }

  disconnect() {
    this.intersectionObserver.unobserve(this.element);
  }

  processIntersectionEntries(entries) {
    entries.forEach((entry) => {
      this.element.classList.toggle(
        this.data.get("class"),
        entry.isIntersecting
      );
    });
  }
}
