// To see this message, add the following to the `<head>` section in your
// views/layouts/application.html.erb
//
//    <%= vite_client_tag %>
//    <%= vite_javascript_tag 'application' %>
console.log('Vite ⚡️ Rails')

// If using a TypeScript entrypoint file:
//     <%= vite_typescript_tag 'application' %>
//
// If you want to use .jsx or .tsx, add the extension:
//     <%= vite_javascript_tag 'application.jsx' %>

console.log('Visit the guide for more information: ', 'https://vite-ruby.netlify.app/guide/rails')

// Load Turbo
import * as Turbo from "@hotwired/turbo";
Turbo.start();

// Load ActiveStorage
import * as ActiveStorage from "@rails/activestorage";
ActiveStorage.start();

// Import all channels.
const channels = import.meta.globEager("./**/*_channel.js");

// Import stylesheets
import "~/stylesheets/scss_scaffold.scss";
import "~/stylesheets/main.scss";

// TODO: remove lozad in favor of lazysizes
import "lazysizes";

// Load Stimulus
import { Application } from "@hotwired/stimulus";
import { registerControllers } from "stimulus-vite-helpers";

const application = Application.start();
const controllers = import.meta.globEager("~/controllers/**/*_controller.js");
registerControllers(application, controllers);

const typescriptControllers = import.meta.globEager(
  "~/controllers/**/*_controller.ts"
);
registerControllers(application, typescriptControllers);

// // Load Stimulus Flatpickr
// import Flatpickr from "stimulus-flatpickr";
// require("flatpickr/dist/flatpickr.css");
// application.register("flatpickr", Flatpickr);

import Trix from "trix"

import "../initializers/setup_message-bus.js"
import "../initializers/setup_message-bus-ajax.js"
import "../initializers/setup_direct_uploads.js"
