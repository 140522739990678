import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static get targets() {
    return [];
  }

  connect() {}

  success(e) {
    const authToken = e.detail[2].getResponseHeader('Authorization');
    localStorage.setItem('project-user-resource-form-token', authToken);

    const successUrl = this.data.get('successUrl');
    if (successUrl) {
      Turbo.visit(successUrl);
    }
  }

  error(e) {
    alert(JSON.stringify(e.detail[0]));
  }

  disconnect() {}
}