import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static get targets() {
    return ['token'];
  }

  connect() {
    console.log('product form');
  }

  send(e) {
    e.detail[1].data += `&cart_id=${localStorage.getItem('cart-id') || ''}`
    e.detail[1].data += `&cart_item_count=${localStorage.getItem('cart-item-count') || ''}`
  }

  success(e) {
    this.element.reset();

    if (e.detail.length > 0) {
      const data = e.detail[0];
      console.log('[product form]', data);

      localStorage.setItem('cart-id', data.cartId);
      localStorage.setItem('cart-item-count', data.cartItemCount);

      const cart = document.querySelector('*[data-controller="cart-icon"]');
      console.log('cart:', cart);
      if (cart && cart['cart-icon']) {
        console.log('update cart...');
        cart['cart-icon'].update();
      }

      const modal = document.querySelector('*[data-controller="product-cart-modal"]');
      console.log('modal:', modal);
      if (modal && modal['product-cart-modal']) {
        console.log('update modal...');
        modal.dataset['productCartModalData'] = JSON.stringify(data);
        modal['product-cart-modal'].update();
      }
    }
  }

  error(e) {
    if (e.detail.length === 0 || !e.detail[0].errors) {
      return;
    }

    const errors = e.detail[0].errors;
    console.error(errors);
  }

  disconnect() {}
}