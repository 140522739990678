import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static get targets() {
    return ["button", "editor"];
  }

  connect() {}

  open() {
    if (this.editorTarget) {
      this.buttonTarget.remove();
      this.editorTarget.style.display = "block";
      this.editorTarget.innerHTML = `<trix-editor input="${this.data.get(
        "input-name"
      )}"></trix-editor>`;
    }
  }

  disconnect() {}
}
