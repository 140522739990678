import { Controller } from "@hotwired/stimulus";
import * as FilePond from "filepond";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import DirectUploader from "../direct_uploader";

import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";

FilePond.registerPlugin(
  FilePondPluginFileValidateType,
  FilePondPluginFileValidateSize
);

export default class extends Controller {
  static get targets() {
    return ["input", "pond"];
  }

  connect() {
    if (!this.hasInputTarget) {
      return;
    }

    this.maxFiles = parseInt(this.data.get("max-files"), 10);
    this.allowMultiple = this.maxFiles > 1;
    this.maxFileSizeMb = parseInt(this.data.get("max-file-size-mb"), 10);
    if (this.data.get("accepted-files").length > 0) {
      this.acceptedFiles = this.data.get("accepted-files").split(",");
    }

    const { dataset } = this.inputTarget;

    const { directUploadUrl } = dataset;

    const options = {
      // disables the credits text in the bottom right of the uploader
      credits: {},
      // FileTypeValidation
      // https://pqina.nl/filepond/docs/patterns/plugins/file-validate-type/
      allowFileTypeValidation: true,
      acceptedFileTypes: this.acceptedFiles || [],
      // FileSizeValidation
      // https://pqina.nl/filepond/docs/patterns/plugins/file-validate-size/
      allowFileSizeValidation: true,
      maxFileSize: this.maxFileSizeMb * 1000 * 1000, // Bytes
      // Main configuration
      // https://pqina.nl/filepond/docs/patterns/api/filepond-instance/
      labelIdle: this.allowMultiple
        ? 'Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
        : 'Drag & Drop your file or <span class="filepond--label-action">Browse</span>',
      allowMultiple: this.allowMultiple,
      maxFiles: this.maxFiles,
      server: {
        process: (
          fieldName,
          file,
          metadata,
          load,
          error,
          progress,
          abort,
          transfer,
          options
        ) => {
          const uploader = new DirectUploader(file, directUploadUrl, {
            uploadStart: () => {},
            uploadProgress: (e) => {
              progress(e.lengthComputable, e.loaded, e.total);
            },
            uploadSuccess: (blob) => {
              load(blob.signed_id);
            },
            uploadError: () => {
              error("woops");
            },
          });
          uploader.start();

          return {
            abort: () => {
              uploader.abort();

              abort();
            },
          };
        },
      },
    };
    FilePond.create(this.inputTarget, options);
  }
}
