import { Controller } from "@hotwired/stimulus";
import Rails from "@rails/ujs";

export default class extends Controller {
  static get targets() {
    return ['status', 'result'];
  }

  connect() {
    fetch(this.data.get('url'), {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": Rails.csrfToken(),
        },
        credentials: "same-origin",
      })
      .then((response) => response.json())
      .then((r) => {
        this.statusTarget.innerHTML = 'Done. <a href="#" data-action="section-template-export#copy">Copy to clipboard</a>';
        this.resultTarget.innerHTML = r.data;
      });
  }

  copy(e) {
    e.preventDefault();
    const el = document.createElement("textarea");
    el.value = this.resultTarget.innerHTML;
    el.setAttribute("readonly", "");
    el.style.position = "absolute";
    el.style.left = "-9999px";
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);

    alert('Copied!');
  }

  disconnect() {}
}