import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static get targets() {
    return ["label"];
  }

  initialize() {}

  connect() {
    throw new Error("Not implemented");
  }

  disconnect() {}

  updateValue(e) {
    const newValue = e.currentTarget.value;

    this.setLabelText(newValue);
  }

  // private

  setLabelText(value) {
    // the arg 'value' is used in eval
    if (this.hasLabelTarget) {
      const label = this.data.get("label");

      // TODO: this is a security risk
      // this.labelTarget.innerHTML = eval(label);
    }
  }
}
