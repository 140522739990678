import { ApplicationController } from "stimulus-use";

export default class extends ApplicationController {
  rootElement: HTMLElement;
  gridElement: HTMLElement;
  childElement: HTMLElement;
  isResizing: boolean;
  containerWidth: number;
  containerHeight: number;
  containerX: number;
  containerY: number;
  mouseX: number;
  mouseY: number;
  resizeBottomRightBind: any; //?
  stopResizeBottomRightBind: any; //?
  resizeTopLeftBind: any; //?
  stopResizeTopLeftBind: any; //?

  connect() {
    console.log("grid_child_overlay connected");

    const topLeftResizer = document.createElement("div");
    topLeftResizer.classList.add("section-editor__child-overlay-resizer");
    topLeftResizer.classList.add(
      "section-editor__child-overlay-resizer-top-left"
    );
    topLeftResizer.dataset.action =
      "mousedown->grid-child-overlay#startResizeTopLeft";
    this.element.appendChild(topLeftResizer);

    const bottomRightResizer = document.createElement("div");
    bottomRightResizer.classList.add("section-editor__child-overlay-resizer");
    bottomRightResizer.classList.add(
      "section-editor__child-overlay-resizer-bottom-right"
    );
    bottomRightResizer.dataset.action =
      "mousedown->grid-child-overlay#startResizeBottomRight";
    this.element.appendChild(bottomRightResizer);

    this.childElement = document.querySelector(
      `[data-editor-id='${this.element.dataset.childId}']`
    );
    this.gridElement = this.childElement.parentElement;

    this.isResizing = false;
    this.rootElement = document.querySelector(
      `[data-editor-id='${this.element.dataset.rootElementId}']`
    );
  }

  // triggered by clicking child overlay
  select(e) {
    if (this.isResizing === true) {
      this.isResizing = false;

      return;
    }

    // this.cleanupEditorTarget();
    // this.changeEditorTarget(
    //   document.querySelector(`[data-editor-id='${e.target.dataset.childId}']`)
    // );

    this.dispatch("selectNode", { event: e });
  }

  startResizeBottomRight(e) {
    e.preventDefault();

    this.isResizing = true;

    this.containerWidth = parseFloat(
      getComputedStyle(this.element, null)
        .getPropertyValue("width")
        .replace("px", "")
    );

    this.containerHeight = parseFloat(
      getComputedStyle(this.element, null)
        .getPropertyValue("height")
        .replace("px", "")
    );

    this.containerX = this.element.getBoundingClientRect().left;
    this.containerY = this.element.getBoundingClientRect().top;

    this.mouseX = e.pageX;
    this.mouseY = e.pageY;

    this.resizeBottomRightBind = this.resizeBottomRight.bind(this);
    this.stopResizeBottomRightBind = this.stopResizeBottomRight.bind(this);
    window.addEventListener("mousemove", this.resizeBottomRightBind);
    window.addEventListener("mouseup", this.stopResizeBottomRightBind);
    console.log("startResizeBottomRight");
  }

  resizeBottomRight(e) {
    const width = this.containerWidth + (e.pageX - this.mouseX);
    const height = this.containerHeight + (e.pageY - this.mouseY);

    if (width > 20) {
      this.element.style.width = `${width}px`;
    }

    if (height > 20) {
      this.element.style.height = `${height}px`;
    }
  }

  stopResizeBottomRight(e) {
    e.preventDefault();

    window.removeEventListener("mousemove", this.resizeBottomRightBind);
    window.removeEventListener("mouseup", this.stopResizeBottomRightBind);

    let gridColumnEnd: number;
    let gridRowEnd: number;

    const width = this.containerWidth + (e.pageX - this.mouseX);
    if (width > 20) {
      const gridColumns = window
        .getComputedStyle(this.gridElement) // FYI was this.currentElement
        .gridTemplateColumns.split(" ")
        .map((col) => {
          return parseInt(col.replace("px", ""));
        });
      const gridEndX =
        e.clientX - this.gridElement.getBoundingClientRect().left;

      for (let i = 0, columnsSum = 0; i < gridColumns.length; i++) {
        columnsSum += gridColumns[i];

        console.log("gridEndX: ", gridEndX, "columnsSum: ", columnsSum);

        if (gridEndX < columnsSum) {
          console.log("grid-column-end: ", i + 1);
          gridColumnEnd = i + 1;

          i = gridColumns.length;
        }
      }
    }

    const height = this.containerHeight + (e.pageY - this.mouseY);

    if (height > 20) {
      const gridRows = window
        .getComputedStyle(this.gridElement) // FYI was this.currentElement
        .gridTemplateRows.split(" ")
        .map((row) => {
          return parseFloat(row.replace("px", ""));
        });
      const gridEndY = e.clientY - this.gridElement.getBoundingClientRect().top;

      for (let i = 0, rowsSum = 0; i < gridRows.length; i++) {
        rowsSum += gridRows[i];

        console.log("gridEndY: ", gridEndY, "rowsSum: ", rowsSum);

        if (gridEndY < rowsSum) {
          console.log("grid-rows-end: ", i + 1);
          gridRowEnd = i + 1;

          i = gridRows.length;
        }
      }
    }

    this.dispatch("resizeGrid", {
      mode: "bottomRight",
      gridColumnEnd,
      gridRowEnd,
      target: this.childElement,
    });
  }

  startResizeTopLeft(e) {
    e.preventDefault();

    this.isResizing = true;
    this.containerWidth = parseFloat(
      getComputedStyle(this.element, null)
        .getPropertyValue("width")
        .replace("px", "")
    );

    this.containerHeight = parseFloat(
      getComputedStyle(this.element, null)
        .getPropertyValue("height")
        .replace("px", "")
    );
    this.containerX = this.element.getBoundingClientRect().left;
    this.containerY = this.element.getBoundingClientRect().top;
    this.mouseX = e.pageX;
    this.mouseY = e.pageY;

    this.resizeTopLeftBind = this.resizeTopLeft.bind(this);
    this.stopResizeTopLeftBind = this.stopResizeTopLeft.bind(this);
    window.addEventListener("mousemove", this.resizeTopLeftBind);
    window.addEventListener("mouseup", this.stopResizeTopLeftBind);
  }

  resizeTopLeft(e) {
    const width = this.containerWidth - (e.pageX - this.mouseX);
    const height = this.containerHeight - (e.pageY - this.mouseY);

    if (width > 20) {
      this.element.style.width = `${width}px`;
      this.element.style.left =
        this.containerX + (e.pageX - this.mouseX) + "px";
    }

    if (height > 20) {
      this.element.style.height = `${height}px`;
      this.element.style.top = this.containerY + (e.pageY - this.mouseY) + "px";
    }
  }

  stopResizeTopLeft(e) {
    e.preventDefault();

    window.removeEventListener("mousemove", this.resizeTopLeftBind);
    window.removeEventListener("mouseup", this.stopResizeTopLeftBind);

    let gridColumnStart: number;
    let gridRowStart: number;

    const width = this.containerWidth - (e.pageX - this.mouseX);
    if (width > 20) {
      const gridColumns = window
        .getComputedStyle(this.gridElement) // FYI was this.currentElement
        .gridTemplateColumns.split(" ")
        .map((col) => {
          return parseInt(col.replace("px", ""));
        });
      const gridStartX = e.pageX - this.rootElement.offsetLeft;

      for (let i = 0, columnsSum = 0; i < gridColumns.length; i++) {
        columnsSum += gridColumns[i];

        console.log("gridStartX: ", gridStartX, "columnsSum: ", columnsSum);

        if (gridStartX < columnsSum) {
          console.log("grid-column-start: ", i + 1);
          gridColumnStart = i + 1;

          i = gridColumns.length;
        }
      }
    }

    const height = this.containerHeight - (e.pageY - this.mouseY);
    if (height > 20) {
      const gridRows = window
        .getComputedStyle(this.gridElement) // FYI was this.currentElement
        .gridTemplateRows.split(" ")
        .map((row) => {
          return parseFloat(row.replace("px", ""));
        });
      const gridStartY = e.pageY - this.rootElement.offsetTop;

      for (let i = 0, rowsSum = 0; i < gridRows.length; i++) {
        rowsSum += gridRows[i];

        // console.log("gridEndY: ", gridEndY, "rowsSum: ", rowsSum);

        if (gridStartY < rowsSum) {
          console.log("grid-row-start: ", i + 1);
          gridRowStart = i + 1;

          i = gridRows.length;
        }
      }
    }

    this.dispatch("resizeGrid", {
      mode: "topLeft",
      gridColumnStart,
      gridRowStart,
      target: this.childElement,
    });
  }
}
