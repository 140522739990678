import { Controller } from "@hotwired/stimulus";
import morphdom from "morphdom";
import decodeHtml from "../helpers/decode_html";

export default class extends Controller {
  static get targets() {
    return ["loadingText"];
  }

  connect() {
    this.textList = [
      "Importing...",
      "Importing.",
      "Importing..",
      "Importing...",
      "Importing.",
      "Importing..",
    ];
    this.currentTextIndex = 0;

    this.loaderInterval = setInterval(() => {
      this.showNextLoader();
    }, 1000);

    MessageBus.start();
    MessageBus.subscribe(
      this.data.get("channel-name"),
      this.processMessage.bind(this),
      parseInt(this.data.get("last-id"), 10)
    );
  }

  showNextLoader() {
    if (this.hasLoadingTextTarget) {
      this.loadingTextTarget.innerHTML = this.textList[this.currentTextIndex];
      this.currentTextIndex += 1;

      if (this.currentTextIndex >= this.textList.length) {
        this.currentTextIndex = 0;
      }
    }
  }

  processMessage(data) {
    if (data.id === this.data.get("id") && data.encoded_html.length > 0) {
      try {
        morphdom(
          document.querySelector("#product-csv-imports"),
          decodeHtml(data.encoded_html),
          {
            childrenOnly: true,
          }
        );
      } catch (err) {
        console.error(err);
      }

      const channelName = this.data.get("channel-name");
      if (channelName) {
        MessageBus.unsubscribe(channelName);
        MessageBus.stop();
      }
    }
  }

  disconnect() {
    if (this.loaderInterval) {
      clearInterval(this.loaderInterval);
    }
  }
}
