import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static get targets() {
    return ['notarobot', 'key', 'token'];
  }

  connect() {}

  success(e) {
    if (e.detail.length > 0 && e.detail[0].message) {
      alert(e.detail[0].message);
    }

    this.element.reset();
  }

  send(e) {
    // this.cleanErrors();

    e.preventDefault();

    alert('This is a test preview. No data was sent.');

    this.element.reset();
  }

  error(e) {
    if (e.detail.length === 0 || !e.detail[0].errors) {
      return;
    }

    const errors = e.detail[0].errors;
    this.processError(errors);
  }

  processError(errors) {
    if (errors.length === 1 && errors[0].length === 2 && errors[0][0] === 'form') {
      alert(errors[0][1]);
      this.element.reset();
    } else {
      errors.forEach((error) => {
        const key = error[0];
        const message = error[1];

        const errorFields = this.element.querySelectorAll(`*[data-error-name="${key}"]`);
        errorFields.forEach((errorField) => {
          errorField.innerText = message;
        });

        console.error(key, message);
      });
    }
  }

  cleanErrors() {
    this.element.querySelectorAll('.input-error').forEach((errorField) => {
      errorField.innerText = '';
    });
  }

  disconnect() {}
}