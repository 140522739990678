import {
  DirectUpload
} from '@rails/activestorage';

export default class DirectUploader {
  constructor(file, url, callbacks) {
    this.file = file;
    this.url = url;
    this.callbacks = callbacks;

    this.directUpload = new DirectUpload(file, url, this);
  }

  start() {
    this.callbacks.uploadStart();

    this.directUpload.create((error, blob) => {
      if (error) {
        this.callbacks.uploadError(error);
      } else {
        this.callbacks.uploadSuccess(blob);
      }
    });
  }

  abort() {
    // TODO: ?
    console.error('something weird happened...');
  }

  directUploadWillStoreFileWithXHR(request) {
    request.upload.addEventListener(
      'progress',
      (event) => this.directUploadDidProgress(event),
    );
  }

  directUploadDidProgress(event) {
    // const progress = (event.loaded * 100) / event.total;

    this.callbacks.uploadProgress(event);
  }
}