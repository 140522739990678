import { Controller } from "@hotwired/stimulus";
import Picker from "vanilla-picker";

export default class extends Controller {
  static get targets() {
    return ["button", "input"];
  }

  connect() {
    if (this.hasButtonTarget && this.hasInputTarget) {
      StimulusReflex.register(this);

      this.picker = new Picker({
        parent: this.buttonTarget,
        onDone: this.updateColor.bind(this),
        popup: "left",
        editorFormat: "hex",
        cancelButton: true,
        color: this.inputTarget.value,
      });
    } else {
      console.error("Button or input not defined.");
    }
  }

  updateColor(color) {
    this.inputTarget.value = color.hex;
    this.stimulate("SectionDataReflex#submit", this.inputTarget);
  }

  disconnect() {
    if (this.picker) {
      this.picker.destroy();
    }
  }
}
