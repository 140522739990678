import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static get targets() {
    return ["link"];
  }

  connect() {
    // debugger
    // this.linkTargets.forEach((l) => {
    //   l.addEventListener('hover', this.)
    // });
  }

  hover(e) {
    // debugger
    // TODO: wtf is up with all those triggered events? mouseover is triggered when hovering over the properties div too
    console.log(e.target.dataset.sectionId);
    const sectionId = e.target.dataset.sectionId;

    if (sectionId) {
      const section = document.querySelector(`#preview-section-${sectionId}`);
      if (section) {
        section.scrollIntoView({ behavior: "smooth" });
      } else {
        console.warn(
          `Wanted to scroll the page but could not find '#preview-section-${sectionId}'.`
        );
      }
    }
  }

  disconnect() {}
}
