import { Controller } from "@hotwired/stimulus";
export default class extends Controller {
  static targets = [];

  connect() {
    console.log("remap me!");
    // this.element[this.identifier] = this;
    // console.log("[edit preview controller] connected");
    // this.element.dataset.editorPreviewConnected = "true";
  }

  remap(e) {}

  disconnect() {}
}
