import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static get targets() {
    return ["preview", "resizer", "properties"];
  }

  connect() {
    const savedWidth = localStorage.getItem("editor.propertiesWidth");
    this.mode = localStorage.getItem("editor.mode");

    if (this.mode === "full") {
      this.propertiesTarget.style.width = "100%";
      this.resizerTarget.classList.add("editor__resizer--right");
    } else if (this.mode === "closed") {
      this.propertiesTarget.style.width = "0";
      this.resizerTarget.classList.remove("editor__resizer--right");
    } else if (savedWidth) {
      this.propertiesTarget.style.width = `${savedWidth}px`;
      this.resizerTarget.classList.remove("editor__resizer--right");
    } else {
      this.wiggling = true;
      this.resizerTarget.classList.add("editor__resizer--wiggle");
    }
  }

  // mouse down starts to drag properties
  mousedown(e) {
    e.preventDefault();
    this.dragging = true;

    this.resizeEvent = this.resize.bind(this);
    this.mouseUpEvent = this.mouseUp.bind(this);

    document.addEventListener("mousemove", this.resizeEvent);
    document.addEventListener("mouseup", this.mouseUpEvent);

    if (this.wiggling) {
      this.resizerTarget.classList.remove("editor__resizer--wiggle");
      this.wiggling = false;
    }
  }

  resize(e) {
    e.preventDefault();

    this.resizerTarget.style.cursor = "col-resize";
    document.body.style.cursor = "col-resize";

    this.previewTarget.style.userSelect = "none";
    this.previewTarget.style.pointerEvents = "none";

    this.propertiesTarget.style.userSelect = "none";
    this.propertiesTarget.style.pointerEvents = "none";

    this.resizePropertiesTo(e.clientX);
  }

  mouseUp(e) {
    e.preventDefault();
    document.removeEventListener("mousemove", this.resizeEvent);
    document.removeEventListener("mouseup", this.mouseUpEvent);

    this.resizerTarget.style.removeProperty("cursor");
    document.body.style.removeProperty("cursor");

    this.previewTarget.style.removeProperty("user-select");
    this.previewTarget.style.removeProperty("pointer-events");

    this.propertiesTarget.style.removeProperty("user-select");
    this.propertiesTarget.style.removeProperty("pointer-events");

    if (this.dragging) {
      window.dispatchEvent(new Event("resize"));
      localStorage.setItem("editor.propertiesWidth", this.currentWidth);
      localStorage.setItem("editor.mode", this.mode);
      this.dragging = false;
    }
  }

  // open/close properties
  toggle() {
    this.dragging = false;

    if (this.mode === "full" || this.mode === "partial") {
      this.currentWidth = 0;
      this.propertiesTarget.style.width = "0";
      this.resizerTarget.classList.remove("editor__resizer--right");
      this.isOpen = false;
      this.mode = "closed";
    } else {
      this.currentWidth = 0;
      this.propertiesTarget.style.width = "100%";
      this.resizerTarget.classList.add("editor__resizer--right");
      this.isOpen = true;
      this.mode = "full";
    }

    localStorage.setItem("editor.propertiesWidthPercent", this.currentWidth);
    localStorage.setItem("editor.mode", this.mode);

    if (this.wiggling) {
      this.resizerTarget.classList.remove("editor__resizer--wiggle");
      this.wiggling = false;
    }

    window.dispatchEvent(new Event("resize"));
  }

  // touch events
  touchstart(e) {
    this.dragging = false;
    e.preventDefault();

    this.touchMoveEvent = this.touchmove.bind(this);
    this.touchEndEvent = this.touchend.bind(this);

    document.addEventListener("touchmove", this.touchMoveEvent);
    document.addEventListener("touchend", this.touchEndEvent);

    if (this.wiggling) {
      this.resizerTarget.classList.remove("editor__resizer--wiggle");
      this.wiggling = false;
    }
  }

  touchmove(e) {
    e.preventDefault();
    this.dragging = true;

    this.resizePropertiesTo(e.touches[0].clientX);
  }

  touchend(e) {
    e.preventDefault();
    if (!this.dragging) {
      this.toggle();
    }

    document.removeEventListener("touchmove", this.touchMoveEvent);
    document.removeEventListener("touchend", this.touchEndEvent);

    if (this.dragging) {
      window.dispatchEvent(new Event("resize"));
      localStorage.setItem("editor.propertiesWidth", this.currentWidth);
      localStorage.setItem("editor.mode", this.mode);
      this.dragging = false;
    }
  }

  resizePropertiesTo(clientX) {
    const containerWidth = this.element.getBoundingClientRect().width;
    let diffInPixels = containerWidth - clientX;
    if (diffInPixels < 0) {
      diffInPixels = 0;
    } else if (clientX >= containerWidth) {
      diffInPixels = containerWidth;
    }

    if (diffInPixels <= 100) {
      this.resizerTarget.classList.remove("editor__resizer--right");
      this.mode = "closed";
      this.propertiesTarget.style.width = "0";
      this.currentWidth = 0;
    } else if (clientX <= 100) {
      this.resizerTarget.classList.add("editor__resizer--right");
      this.mode = "full";
      this.propertiesTarget.style.width = "100%";
      this.currentWidth = 0;
    } else {
      this.resizerTarget.classList.remove("editor__resizer--right");
      this.mode = "partial";
      this.propertiesTarget.style.width = `${diffInPixels}px`;
      this.currentWidth = diffInPixels;
    }
  }

  disconnect() {
    if (this.resizeEvent) {
      document.removeEventListener("mousemove", this.resizeEvent);
    }

    if (this.mouseUpEvent) {
      document.removeEventListener("mouseup", this.mouseUpEvent);
    }

    if (this.touchMoveEvent) {
      document.removeEventListener("touchmove", this.touchMoveEvent);
    }

    if (this.touchEndEvent) {
      document.removeEventListener("touchend", this.touchEndEvent);
    }
  }
}
