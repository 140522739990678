import { Controller } from "@hotwired/stimulus";
import * as FilePond from "filepond";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginImageResize from "filepond-plugin-image-resize";
import FilePondPluginImageTransform from "filepond-plugin-image-transform";
import DirectUploader from "../direct_uploader";
import Rails from "@rails/ujs";

import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";

FilePond.registerPlugin(
  FilePondPluginFileValidateType,
  FilePondPluginFileValidateSize,
  FilePondPluginImagePreview,
  FilePondPluginImageResize,
  FilePondPluginImageTransform
);

export default class extends Controller {
  static get targets() {
    return ["input", "pond", "imagePreview"];
  }

  connect() {
    if (!this.hasInputTarget) {
      return;
    }

    this.element[this.identifier] = this;

    this.maxFiles = parseInt(this.data.get("max-files"), 10);
    this.allowMultiple = this.maxFiles > 1;
    this.maxFileSizeMb = parseInt(this.data.get("max-file-size-mb"), 10);
    this.acceptedFiles = this.data.get("accepted-files").split(",");

    const { dataset } = this.inputTarget;

    const { directUploadUrl } = dataset;

    const initialFiles = [];
    if (this.element && this.hasImagePreviewTarget) {
      this.imagePreviewTargets.forEach((imagePreviewTarget) => {
        initialFiles.push({
          source: imagePreviewTarget.value,
          options: {
            type: "local",
          },
        });
      });
    }

    const options = {
      // disables the credits text in the bottom right of the uploader
      credits: {},
      files: initialFiles,
      // FileTypeValidation
      // https://pqina.nl/filepond/docs/patterns/plugins/file-validate-type/
      allowFileTypeValidation: true,
      acceptedFileTypes: this.acceptedFiles,
      // FileSizeValidation
      // https://pqina.nl/filepond/docs/patterns/plugins/file-validate-size/
      allowFileSizeValidation: true,
      maxFileSize: this.maxFileSizeMb * 1000 * 1000, // Bytes
      // ImagePreview
      // https://pqina.nl/filepond/docs/patterns/plugins/image-preview/
      allowImagePreview: true,
      imagePreviewHeight: this.data.get("item-height") || 400,
      // FilePondPluginImageResize
      // https://pqina.nl/filepond/docs/patterns/plugins/image-resize/
      imageResizeTargetWidth: 4800,
      // imageResizeTargetHeight: 1440,
      imageResizeMode: "contain",
      imageResizeUpscale: false,
      // FilePondPluginImageTransform
      // https://pqina.nl/filepond/docs/patterns/plugins/image-transform/
      imageTransformOutputQuality: 100,
      // Main configuration
      // https://pqina.nl/filepond/docs/patterns/api/filepond-instance/
      labelIdle: this.allowMultiple
        ? 'Drag & Drop your pictures or <span class="filepond--label-action">Browse</span>'
        : 'Drag & Drop your picture or <span class="filepond--label-action">Browse</span>',
      allowMultiple: this.allowMultiple,
      maxFiles: this.maxFiles,
      server: {
        load: (source, load, error, progress, abort, headers) => {
          const previewInput = document.querySelectorAll(
            `input[value="${source}"]`
          )[0];

          if (!previewInput) {
            return;
          }

          const previewUrl = previewInput.dataset.url;

          this.controller = new AbortController();
          const { signal } = this.controller;

          fetch(previewUrl, { signal })
            .then((response) => response.blob())
            .then((blob) => {
              progress(true, 0, 1024);
              load(blob);
            })
            .catch((e) => {
              console.log("error: ", e);
              if (e.name !== "AbortError") {
                error();
              }
            });

          return {
            abort: () => {
              if (this.controller) {
                this.controller.abort();
              }
            },
          };
        },
        process: (
          fieldName,
          file,
          metadata,
          load,
          error,
          progress,
          abort,
          transfer,
          options
        ) => {
          const uploader = new DirectUploader(file, directUploadUrl, {
            uploadStart: () => {},
            uploadProgress: (e) => {
              progress(e.lengthComputable, e.loaded, e.total);
            },
            uploadSuccess: (blob) => {
              load(blob.signed_id);
            },
            uploadError: () => {
              error("woops");
            },
          });
          uploader.start();

          return {
            abort: () => {
              uploader.abort();

              abort();
            },
          };
        },
        remove: (source, done, baz) => {
          const removeUrl = this.data.get("remove-url");
          if (!removeUrl) {
            console.error("Please set the remove-url attribute");

            return;
          }

          const previewInput = document.querySelectorAll(
            `input[value="${source}"]`
          )[0];

          if (!previewInput) {
            console.error("No preview...");
            done();

            return;
          }

          fetch(removeUrl, {
            method: "DELETE",
            headers: {
              "X-CSRF-Token": Rails.csrfToken(),
              "Content-Type": "application/json",
            },
            credentials: "same-origin",
          })
            .then((response) => response.json())
            .then((data) => {
              console.log(data);
              previewInput.parentNode.removeChild(previewInput);

              done();
            })
            .catch((e) => {
              console.error(e);
            });
        },
      },
    };
    this.filepondElement = this.inputTarget;
    FilePond.create(this.inputTarget, options);
  }

  disconnect() {
    if (this.filepondElement) {
      FilePond.destroy(this.filepondElement);
    }
  }
}
