import { Controller } from "@hotwired/stimulus";
// TODO: move to a separate chunk
import { transform } from "@babel/standalone";

export default class extends Controller {
  static get targets() {
    return [];
  }

  static kebabToCamel(input) {
    return input.replace(/-([a-z])/g, function (g) {
      return g[1].toUpperCase();
    });
  }

  connect() {
    const code = this.element.value;
    const compiledCode = this.compile(code);

    this.registerComponent(
      this.element.name.replace("-js-controller", ""),
      "ComponentController",
      compiledCode
    );
  }

  compile(code) {
    return transform(code, {
      filename: "component.ts",
      presets: [
        ["env", { shippedProposals: true }],
        ["typescript", { allowDeclareFields: true }],
      ],
      plugins: [
        // "proposal-class-properties",
        "proposal-object-rest-spread",
      ],
    }).code;
  }

  registerComponent(name, className, script) {
    const scriptTag = document.createElement("script");
    scriptTag.type = "module";
    scriptTag.innerHTML = `
      ${script};
      application.register('${name}', ${className});
    `;
    document.head.appendChild(scriptTag);
  }

  disconnect() {}
}
