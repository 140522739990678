import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static get targets() {
    return [
      "isCompany",
      "companyDetails",
      "companyInput",
      "requiredCompanyInput",
    ];
  }

  connect() {
    if (this.isCompanyTarget.checked) {
      this.companyDetailsTarget.style.display = "";
      this.requiredCompanyInputTargets.forEach((input) => {
        input.required = true;
      });
    } else {
      this.companyDetailsTarget.style.display = "none";
      this.requiredCompanyInputTargets.forEach((input) => {
        input.required = false;
      });
    }
  }

  disconnect() {}

  changeIsCompany(e) {
    if (e.target.checked) {
      this.companyDetailsTarget.style.display = "";
      this.requiredCompanyInputTargets.forEach((input) => {
        input.required = true;
      });
    } else {
      this.companyDetailsTarget.style.display = "none";
      this.companyInputTargets.forEach((input) => {
        input.value = "";
      });
      this.requiredCompanyInputTargets.forEach((input) => {
        input.required = false;
      });
    }
  }
}
