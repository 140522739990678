import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static get targets() {
    return [];
  }

  connect() {}

  deleted(e) {
    const rowNode = e.target.closest('tr');

    if (rowNode) {
      rowNode.classList.add('table__row--deleted');
      setTimeout(() => {
        rowNode.remove();
      }, 200);
    }
  }

  disconnect() {}
}