import { Controller } from "@hotwired/stimulus";
import Rails from "@rails/ujs";
export default class extends Controller {
  static targets = ["action", "preview", "modal", "modalContent"];

  connect() {
    this.element[this.identifier] = this;
    console.log("[page editor controller] connected");

    this.waitForEditorPreview();
  }

  waitForEditorPreview() {
    this.waitForEditorPreviewInterval = setInterval(() => {
      const pagePreview =
        this.previewTarget.contentWindow.document.querySelector(
          "#page-preview"
        );
      if (
        pagePreview &&
        pagePreview.dataset.editorPreviewConnected === "true"
      ) {
        this.editorPreviewController = pagePreview["editor-preview"];
        this.editorPreviewController.setPageEditor(this);

        clearInterval(this.waitForEditorPreviewInterval);
        this.waitForEditorPreviewInterval = null;
      }
    }, 100);
  }

  editTexts(e) {
    this.actionTargets.forEach((button) => {
      button.classList.add("pulse-button--transparent");
    });

    e.currentTarget.classList.remove("pulse-button--transparent");

    if (this.editorPreviewController) {
      this.editorPreviewController.disableComponentEditing();
    }
  }

  editComponents(e) {
    this.actionTargets.forEach((button) => {
      button.classList.add("pulse-button--transparent");
    });

    if (this.sections && this.sections.length > 0) {
      e.currentTarget.classList.add("pulse-button--transparent");
    } else {
      e.currentTarget.classList.remove("pulse-button--transparent");
    }

    if (this.editorPreviewController) {
      this.editorPreviewController.enableComponentEditing();
    }
  }

  openModal() {
    this.modalContentTarget.innerHTML = "Loading...";
    this.modalTarget.classList.add("editor__modal--active");
  }

  closeModal() {
    this.modalTarget.classList.remove("editor__modal--active");
  }

  setModalContent(content) {
    this.modalContentTarget.innerHTML = content;
  }

  addComponent(position: number) {
    console.log(`[page editor] addComponent to ${position}`);
    this.openModal();

    // TODO: change the route to scope to project instead of using project_name in param
    fetch(
      `/sections/new?position=${position}&project_name=${this.data.get(
        "projectName"
      )}&page_slug=${this.data.get("pageSlug")}`,
      {
        method: "get",
        headers: {
          "X-CSRF-Token": Rails.csrfToken(),
        },
        credentials: "same-origin",
      }
    )
      .then((response) => response.text())
      .then((data) => {
        this.setModalContent(data);
      });
  }

  replaceComponent(sectionId) {
    console.log(`[page editor] replaceComponent ${sectionId}`);
    this.openModal();

    // TODO: change the route to scope to project instead of using project_name in param
    fetch(
      `/sections/new?project_name=${this.data.get(
        "projectName"
      )}&page_slug=${this.data.get("pageSlug")}&section_id=${sectionId}`,
      {
        method: "get",
        headers: {
          "X-CSRF-Token": Rails.csrfToken(),
        },
        credentials: "same-origin",
      }
    )
      .then((response) => response.text())
      .then((data) => {
        this.setModalContent(data);
      });
  }

  remapComponent(sectionId) {
    console.log(`[page editor] remap ${sectionId}`);
    this.currentRemapComponentSectionId = sectionId;
    this.openModal();

    fetch(`/section_data_structures/${sectionId}/edit`, {
      method: "get",
      headers: {
        "X-CSRF-Token": Rails.csrfToken(),
      },
      credentials: "same-origin",
    })
      .then((response) => response.text())
      .then((data) => {
        this.setModalContent(data);
      });
  }

  remapComponentData(e) {
    const newValue = e.target.value;
    const sectionNodeId = e.target.dataset.sectionNodeId;
    const fromName = e.target.dataset.fromName;

    fetch(`/section_data_structures/${this.currentRemapComponentSectionId}`, {
      method: "PATCH",
      headers: {
        "X-CSRF-Token": Rails.csrfToken(),
        "Content-Type": "application/json",
      },
      credentials: "same-origin",
      body: JSON.stringify({
        name: newValue,
        section_node_id: sectionNodeId,
        from_name: fromName,
      }),
    })
      .then((response) => response.text())
      .then((data) => {
        this.setModalContent(data);
      });
  }

  removeComponent(sectionId) {
    console.log(`[page editor] removeComponent ${sectionId}`);
    const confirmed = confirm(
      "Are you sure you want to remove this component from the page?"
    );
    if (confirmed) {
      fetch(`/sections/${sectionId}.json`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": Rails.csrfToken(),
        },
        credentials: "same-origin",
      })
        .then((response) => response.json())
        .then((data) => {
          // debugger;
          // console.log(data);
          window.location.reload();
        });
    }
  }

  disconnect() {}
}
