class ViewportResize {
  observer: ResizeObserver;
  target: HTMLElement;
  indicator: HTMLElement;

  constructor(target: HTMLElement, indicator: HTMLElement) {
    this.target = target;
    this.indicator = indicator;

    this.observer = new ResizeObserver((entries: ResizeObserverEntry[]) => {
      this.processViewportResize(entries[0]);
    });

    this.observer.observe(this.target);
  }

  processViewportResize(entry: ResizeObserverEntry) {
    const width = entry.contentRect.width;

    if (width >= 1400) {
      this.indicator.innerHTML = "XXL";
    } else if (width >= 1200) {
      this.indicator.innerHTML = "XL";
    } else if (width >= 992) {
      this.indicator.innerHTML = "LG";
    } else if (width >= 768) {
      this.indicator.innerHTML = "MD";
    } else if (width >= 576) {
      this.indicator.innerHTML = "SM";
    } else {
      this.indicator.innerHTML = "XS";
    }
  }

  destroy() {
    if (this.target) {
      this.observer.unobserve(this.target);
    }
  }
}

export default ViewportResize;
