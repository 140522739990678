import { Controller } from "@hotwired/stimulus";
export default class extends Controller {
  static targets = ["addComponentButton", "sectionComponentContainer"];

  connect() {
    this.element[this.identifier] = this;
    console.log("[edit preview controller] connected");
    this.element.dataset.editorPreviewConnected = "true";
  }

  setPageEditor(pageEditor) {
    this.pageEditor = pageEditor;
  }

  enableComponentEditing() {
    this.sectionComponentContainerTargets.forEach((container) => {
      const sectionId = container.dataset.targetSectionId;
      const section = this.element.querySelector(
        `*[data-section-id="${sectionId}"]`
      );
      container.querySelector(".editor__component").appendChild(section);
      container.classList.add("editor__component-container--active");
    });

    this.addComponentButtonTargets.forEach((button) => {
      button.classList.add("editor__add-component-container--active");
    });
  }

  disableComponentEditing() {
    this.sectionComponentContainerTargets.forEach((container) => {
      const sectionId = container.dataset.targetSectionId;
      const section = this.element.querySelector(
        `*[data-section-id="${sectionId}"]`
      );
      container.insertAdjacentElement("afterend", section);
      container.classList.remove("editor__component-container--active");
    });

    this.addComponentButtonTargets.forEach((button) => {
      button.classList.remove("editor__add-component-container--active");
    });
  }

  addComponent(e) {
    if (!this.pageEditor) {
      return;
    }

    const position = e.target.dataset.position;
    this.pageEditor.addComponent(position);
  }

  replaceComponent(e) {
    const container = e.target.closest(".editor__component-container");
    this.pageEditor.replaceComponent(container.dataset.targetSectionId);
  }

  remapComponent(e) {
    const container = e.target.closest(".editor__component-container");
    this.pageEditor.remapComponent(container.dataset.targetSectionId);
  }

  removeComponent(e) {
    const container = e.target.closest(".editor__component-container");
    this.pageEditor.removeComponent(container.dataset.targetSectionId);
  }

  disconnect() {}
}
