import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static get targets() {
    return ['pageType', 'urlInputContainer', 'urlInput', 'title', 'url'];
  }

  connect() {
    console.log("new page controller connected");
    this.userUpdatedUrl = false;
    this.urlInputWasEmpty = !this.urlInputTarget.value;
    this.urlBase = this.data.get('url-base');
    this.currentPageType = this.data.get('page-type');

    if (this.currentPageType === 'homepage') {
      this.urlInputContainerTarget.style.display = 'none';
      this.urlTarget.innerHTML = `${this.urlBase}/`;
    } else {
      this.urlTarget.innerHTML = `${this.urlBase}/${this.urlInputTarget.value}`;
    }
  }

  // keyup will not fire if you paste text into an input element. It doesn't care that anything has changed; it's only aware of keys being pressed.
  updateTitle(e) {
    this.title = e.target.value.toString().trim();
    this.titleTarget.innerHTML = e.target.value;

    if (!this.userUpdatedUrl) {
      this.updateUrl();
    }
  }

  setUrlActive() {
    this.urlTarget.classList.add('tab-preview__url--active');
  }

  setUrlInactive() {
    this.urlTarget.classList.remove('tab-preview__url--active');
  }

  updateUrl(e = null) {
    if (this.currentPageType === 'homepage') {
      this.urlTarget.innerHTML = `${this.urlBase}/`;
    } else {
      let inputValue;
      if (e) {
        inputValue = e.target.value.toString().trim();
      } else {
        inputValue = this.urlInputWasEmpty ? this.title : this.urlInputTarget.value;
      }

      let slug = new String(inputValue || '').toLowerCase()
        .replace(/\s\s+/g, '-')
        .replace(/\s+/g, '-')
        .replaceAll(/[^a-z0-9\-]/g, '')
        .replace(/\-\-+/g, '-');

      if (slug[slug.length - 1] === '-') {
        slug = slug.substring(0, slug.length - 1);
      }

      this.urlTarget.innerHTML = `${this.urlBase}/${slug}${this.data.get('url-suffix') || ''}`;

      if (this.urlInputWasEmpty && !this.userUpdatedUrl) {
        this.urlInputTarget.value = slug;

        if (e) {
          this.userUpdatedUrl = true;
        }
      }
    }
  }

  changePageType(e) {
    this.currentPageType = e.target.value;

    if (this.currentPageType === 'homepage') {
      this.urlInputContainerTarget.style.display = 'none';
    } else {
      this.urlInputContainerTarget.style.display = 'block';
    }

    this.updateUrl();
  }

  disconnect() {}
}