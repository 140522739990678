import { Controller } from "@hotwired/stimulus";
import morphdom from "morphdom";
import decodeHtml from "../helpers/decode_html";

export default class extends Controller {
  static get targets() {
    return ["actions"];
  }

  connect() {
    if (this.data.get("done") === "1") {
      this.actionsTarget.style.display = "block";
    } else {
      MessageBus.start();
      MessageBus.subscribe(
        this.data.get("channel-name"),
        this.processMessage.bind(this),
        parseInt(this.data.get("last-id"), 10)
      );
      this.subscribed = true;
    }
  }

  processMessage(data) {
    if (data.id === this.data.get("id") && data.encoded_html.length > 0) {
      try {
        morphdom(
          this.element.firstElementChild,
          decodeHtml(data.encoded_html),
          {
            childrenOnly: true,
          }
        );
        if (data.done === "1") {
          this.actionsTarget.style.display = "block";
          this.unsubscribe();
        }
      } catch (err) {
        console.error(err);
      }
    }
  }

  unsubscribe() {
    const channelName = this.data.get("channel-name");
    if (channelName) {
      MessageBus.unsubscribe(channelName);
      MessageBus.stop();
    }
    this.subscribed = false;
  }

  disconnect() {
    if (this.subscribed) {
      this.unsubscribe();
    }
  }
}
