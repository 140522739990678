// import { Controller } from "stimulus";
import { ApplicationController } from "stimulus-use";
import Rails from "@rails/ujs";
// morphodom should fix this issue:
// "This module is declared with using 'export =', and can only be used with a
//   default import when using the 'allowSyntheticDefaultImports' flag."
import morphdom from "morphdom";

import ViewportResize from "~/helpers/viewport_resize.ts";
import ConvertCss from "../entrypoints/postcss_frontend";

// How to find dom position within the editor preview:
//
// offsetTop - distance of the outer border of the current element relative
//   to the inner border of the top of the offsetParent node.
//
// scrollTop - distance from the element's top to its topmost visible content.
//
// getBoundingClientRect() - returns a DOMRect object providing information
//   about the size of an element and its position relative to the viewport.
//
// MouseEvent.pageX - X (horizontal) coordinate (in pixels) at which
//   the mouse was clicked, relative to the left edge of the entire document.
//   This includes any portion of the document not currently visible.
//
// MouseEvent.pageY - Y (vertical) coordinate in pixels of the event
//   relative to the whole document. This property takes into account any
//   vertical scrolling of the page.
//
// MouseEvent.clientX - horizontal coordinate within the application's viewport
//   at which the event occurred (as opposed to the coordinate within the page).
//
// MouseEvent.clientY - vertical coordinate within the application's viewport
//   at which the event occurred (as opposed to the coordinate within the page).
//
// Important:
//   - the editor itself has `overflow: hidden` which makes scrollTop to
//     always be 0; Same applies to MouseEvent.pageY
//   - the editor preview has `overflow-y: auto` which makes it possible
//     to use scrollTop

export default class extends ApplicationController {
  static targets = [
    "history",
    "selectable",
    "styles",
    "preview",
    "styleTemplate",
    "styledView",
    "structuredView",
    "editComponent",
    "editComponentContent",
    "styleProperties",
    "stylePropertiesForm",
    "componentPropertiesForm",
    "saveStyles",
    "viewportSize",
  ];

  overlays: {};
  indicators: {};
  _resizeObserver: ResizeObserver;
  _observeResizesResizeObserver: ResizeObserver;
  _gridResizeObserver: ResizeObserver;
  _currentElementResizeObserver: ResizeObserver;
  rootElement: HTMLElement;
  gridOverlay: HTMLElement;
  gridOverlayTargetNode: HTMLElement;
  currentElement: HTMLElement;
  currentElementDisplayProperty: string;
  activeChildElements: HTMLElement[];
  rootId: String;
  currentView: String;
  activePlaceholder: HTMLElement;
  gridChildResizeProperties: {
    gridElement: HTMLElement;
    overlay: HTMLElement;
    containerWidth: number;
    containerHeight: number;
    containerX: number;
    containerY: number;
    mouseX: number;
    mouseY: number;
    isResizing: boolean;
    // resizerNode: HTMLElement;
  };
  resizeGridChildBRBind: any;
  stopGridChildBRResizeBind: any;
  resizeGridChildTLBind: any;
  stopGridChildTLResizeBind: any;
  viewportResize: ViewportResize;
  declare readonly previewTarget: HTMLElement;
  declare readonly editComponentContentTarget: HTMLElement;
  declare readonly stylePropertiesTarget: HTMLElement;
  declare readonly stylesTarget: HTMLElement;
  declare readonly styleTemplateTarget: HTMLElement;
  declare readonly historyTarget: HTMLElement;
  declare readonly styledViewTarget: HTMLElement;
  declare readonly structuredViewTarget: HTMLElement;
  declare readonly saveStylesTarget: HTMLElement;
  declare readonly hasComponentPropertiesFormTarget: boolean;
  declare readonly componentPropertiesFormTarget: HTMLElement;
  declare readonly viewportSizeTarget: HTMLElement;

  connect() {
    this.overlays = {};
    this.indicators = {};

    this._observeResizesResizeObserver = new ResizeObserver(
      (entries: ResizeObserverEntry[]) => {
        this.processObserveResizesResize(entries);
      }
    );

    document
      .querySelectorAll("[data-observe-resizes]")
      .forEach((elem: HTMLElement) => {
        const indicator = document.createElement("div");
        indicator.classList.add("section-editor__indicator");
        this.previewTarget.appendChild(indicator);

        this.indicators[elem.dataset.editorId] = indicator;
        this._observeResizesResizeObserver.observe(elem);
      });

    this._resizeObserver = new ResizeObserver(
      (entries: ResizeObserverEntry[]) => {
        this.processResize(entries);
      }
    );

    this._gridResizeObserver = new ResizeObserver(
      (entries: ResizeObserverEntry[]) => {
        this.processGridResize(entries);
      }
    );

    this._currentElementResizeObserver = new ResizeObserver(
      (entries: ResizeObserverEntry[]) => {
        this.processCurrentElementResize(entries);
      }
    );

    this.activeChildElements = [];
    this.rootId = this.data.get("id");
    this.rootElement = document.querySelector(
      `[data-editor-id='${this.rootId}']`
    );

    this.currentView = "styled";

    const cssContainer = document.querySelector(
      "#section-editor-styles"
    ) as HTMLElement;

    if (ConvertCss) {
      ConvertCss.stringToDom(cssContainer.innerText, cssContainer);
    } else {
      console.error("ConvertCss not found");
    }

    // this.highlightCurrentElement(
    //   this.element.querySelector(`[data-editor-id='${this.rootId}']`)
    // );
    // this.currentElement
    //   .querySelectorAll(':scope > *[data-selectable="true"]')
    //   .forEach((elem) => {
    //     this.highlightChildElement(elem);
    //   });

    this.changeEditorTarget(this.rootElement);

    this.viewportResize = new ViewportResize(
      this.rootElement,
      this.viewportSizeTarget
    );
  }

  processObserveResizesResize(entries: ResizeObserverEntry[]) {
    entries.forEach((entry: ResizeObserverEntry) => {
      const target: HTMLElement = entry.target as HTMLElement;
      const indicator = this.indicators[target.dataset.editorId];

      if (!indicator) {
        console.error(
          `indicator does not exist for element #${target.dataset.editorId}`
        );

        return;
      }

      const width = entry.contentRect.width;
      const { top, left } = entry.target.getBoundingClientRect();
      indicator.style.top = `${top + this.previewTarget.scrollTop}px`;
      indicator.style.left = `${left + this.previewTarget.scrollLeft}px`;

      if (width >= 1400) {
        indicator.innerHTML = "XXL";
      } else if (width >= 1200) {
        indicator.innerHTML = "XL";
      } else if (width >= 992) {
        indicator.innerHTML = "LG";
      } else if (width >= 768) {
        indicator.innerHTML = "MD";
      } else if (width >= 576) {
        indicator.innerHTML = "SM";
      } else {
        indicator.innerHTML = "XS";
      }
    });
  }

  processResize(entries: ResizeObserverEntry[]) {
    const rootRect = this.rootElement.getBoundingClientRect();
    const offsetLeft = this.rootElement.offsetLeft;
    const offsetTop = this.rootElement.offsetTop;

    entries.forEach((entry: ResizeObserverEntry) => {
      const target: HTMLElement = entry.target as HTMLElement;
      const overlay = this.overlays[target.dataset.editorId];

      if (!overlay) {
        console.error(
          `overlay does not exist for element #${target.dataset.editorId}`
        );

        return;
      }

      const newSize = entry.contentRect;
      const { top, left } = entry.target.getBoundingClientRect();

      if (target.dataset.observeResizes === "") {
        const width = newSize.width;

        if (width >= 1400) {
          overlay.classList.add("XXL");
          overlay.classList.remove(...["XS", "SM", "MD", "LG", "XL"]);
        } else if (width >= 1200) {
          overlay.classList.add("XL");
          overlay.classList.remove(...["XS", "SM", "MD", "LG", "XXL"]);
        } else if (width >= 992) {
          overlay.classList.add("LG");
          overlay.classList.remove(...["XS", "SM", "MD", "XL", "XXL"]);
        } else if (width >= 768) {
          overlay.classList.add("MD");
          overlay.classList.remove(...["XS", "SM", "LG", "XL", "XXL"]);
        } else if (width >= 576) {
          overlay.classList.add("SM");
          overlay.classList.remove(...["XS", "MD", "LG", "XL", "XXL"]);
        } else {
          overlay.classList.add("XS");
          overlay.classList.remove(...["SM", "MD", "LG", "XL", "XXL"]);
        }
      }

      overlay.style.left = `${
        left - rootRect.left + offsetLeft + newSize.left
      }px`;
      overlay.style.top = `${top - rootRect.top + offsetTop + newSize.top}px`;
      overlay.style.height = `${newSize.height}px`;
      overlay.style.width = `${newSize.width}px`;
    });
  }

  processGridResize(entries: ResizeObserverEntry[]) {
    const rootRect = this.rootElement.getBoundingClientRect();
    const offsetLeft = this.rootElement.offsetLeft;
    const offsetTop = this.rootElement.offsetTop;

    entries.forEach((entry) => {
      const overlay = this.gridOverlay;

      if (!overlay) {
        console.error("grid overlay does not exist");

        return;
      }

      const newSize = entry.contentRect;
      const { top, left } = entry.target.getBoundingClientRect();

      overlay.style.left = `${
        left - rootRect.left + offsetLeft + newSize.left
      }px`;
      overlay.style.top = `${top - rootRect.top + offsetTop + newSize.top}px`;
      overlay.style.height = `${newSize.height}px`;
      overlay.style.width = `${newSize.width}px`;

      const gridColumns = window
        .getComputedStyle(this.currentElement)
        .gridTemplateColumns.split(" ")
        .map((col) => {
          return col.replace("px", "");
        });

      const gridRows = window
        .getComputedStyle(this.currentElement)
        .gridTemplateRows.split(" ")
        .map((row) => {
          return row.replace("px", "");
        });

      for (let i = 0; i < gridColumns.length; i++) {
        const colWidth = gridColumns[i];
        const gridColumn = overlay.querySelectorAll(".cs-grid-overlay__column")[
          i
        ] as HTMLElement;

        gridColumn.style.width = Number(colWidth) * (i + 1) + "px";
      }

      for (let i = 0; i < gridRows.length; i++) {
        const rowHeight = gridRows[i];
        const gridRow = overlay.querySelectorAll(".cs-grid-overlay__row")[
          i
        ] as HTMLElement;

        gridRow.style.height = Number(rowHeight) * (i + 1) + "px";
      }
    });
  }

  processCurrentElementResize(entries: ResizeObserverEntry[]) {
    entries.forEach((entry) => {
      const nodeDisplayProperty = window.getComputedStyle(entry.target).display;

      if (nodeDisplayProperty !== this.currentElementDisplayProperty) {
        this.currentElementDisplayProperty = nodeDisplayProperty;

        this.cleanupEditorTarget();
        this.changeEditorTarget(this.currentElement);
      }
    });
  }

  highlightCurrentElement(elem: HTMLElement) {
    const currentElementWas = this.currentElement;
    this.currentElement = elem;
    this._resizeObserver.observe(this.currentElement);

    const overlay = document.createElement("div");
    overlay.dataset.name = `overlay-${elem.dataset.editorId}`;
    overlay.classList.add("section-editor__overlay");

    this.previewTarget.appendChild(overlay);
    this.overlays[elem.dataset.editorId] = overlay;

    if (currentElementWas !== this.currentElement) {
      if (this.currentElement.dataset.editorId) {
        this.loadComponentProperties(this.currentElement.dataset.editorId);
        this.loadComponentStyles(this.currentElement.dataset.editorId);
      } else {
        this.editComponentContentTarget.innerHTML = "";
        this.stylePropertiesTarget.innerHTML = "";
      }
    }
  }

  loadComponentStyles(nodeId: string) {
    const sectionTemplateId = this.data.get("templateId");
    const url = `/section_templates/${sectionTemplateId}/section_nodes/${nodeId}/styles`;

    fetch(url, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": Rails.csrfToken(),
      },
      credentials: "same-origin",
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        // console.log(data);
        this.stylePropertiesTarget.innerHTML = data;
      });
  }

  loadComponentProperties(nodeId: string) {
    const sectionTemplateId = this.data.get("templateId");
    const url = `/section_templates/${sectionTemplateId}/section_nodes/${nodeId}/properties`;

    fetch(url, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": Rails.csrfToken(),
      },
      credentials: "same-origin",
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        // console.log(data);
        this.editComponentContentTarget.innerHTML = data;
      });
  }

  // triggered by clicking child overlay
  selectNode(e) {
    this.cleanupEditorTarget();
    this.changeEditorTarget(
      document.querySelector(`[data-editor-id='${e.target.dataset.childId}']`)
    );
  }

  highlightChildElement(elem: HTMLElement) {
    const childOverlay = document.createElement("div");
    childOverlay.dataset.name = `overlay-${elem.dataset.editorId}`;
    childOverlay.dataset.childId = elem.dataset.editorId;
    childOverlay.classList.add("section-editor__child-overlay");
    childOverlay.dataset.action = "click->section-editor#selectNode";

    this.previewTarget.appendChild(childOverlay);
    this.overlays[elem.dataset.editorId] = childOverlay;
    this._resizeObserver.observe(elem);
    this.activeChildElements.push(elem);
  }

  highlightGridChildElement(elem: HTMLElement) {
    const childOverlay = document.createElement("div");
    childOverlay.dataset.name = `overlay-${elem.dataset.editorId}`;
    childOverlay.dataset.controller = "grid-child-overlay";
    childOverlay.dataset.currentElementId =
      this.currentElement.dataset.editorId;
    childOverlay.dataset.rootElementId = this.rootElement.dataset.editorId;
    childOverlay.dataset.childId = elem.dataset.editorId;
    childOverlay.classList.add("section-editor__child-overlay");
    childOverlay.dataset.action = "click->grid-child-overlay#select";

    this.previewTarget.appendChild(childOverlay);
    this.overlays[elem.dataset.editorId] = childOverlay;

    this._resizeObserver.observe(elem);
    this.activeChildElements.push(elem);
  }

  cleanupEditorTarget() {
    this.currentElementDisplayProperty = null;

    if (this.currentElement) {
      this._resizeObserver.unobserve(this.currentElement);
      this._currentElementResizeObserver.unobserve(this.currentElement);

      const overlay = this.overlays[this.currentElement.dataset.editorId];
      if (overlay) {
        this.previewTarget.removeChild(overlay);
        // BUG: not compatible with multiple copies of same element:
        delete this.overlays[this.currentElement.dataset.editorId];
      }
    }

    this.activeChildElements.forEach((elem) => {
      this._resizeObserver.unobserve(elem);

      const overlay = this.overlays[elem.dataset.editorId];
      if (overlay) {
        this.previewTarget.removeChild(overlay);
        delete this.overlays[elem.dataset.editorId];
      }
    });
    this.activeChildElements = [];

    if (this.gridOverlay) {
      this._gridResizeObserver.unobserve(this.gridOverlayTargetNode);
      this.previewTarget.removeChild(this.gridOverlay);
      this.gridOverlay = null;
    }
  }

  changeEditorTarget(target) {
    this.currentElementDisplayProperty =
      window.getComputedStyle(target).display;

    this._currentElementResizeObserver.observe(target);

    this.highlightCurrentElement(target);

    if (this.currentElementDisplayProperty === "grid") {
      this.createGridOverlay(target);
      this.previewTarget.appendChild(this.gridOverlay);
      this._gridResizeObserver.observe(target);

      target
        .querySelectorAll(':scope > *[data-selectable="true"]')
        .forEach((e) => {
          this.highlightGridChildElement(e);
        });
    } else {
      target
        .querySelectorAll(':scope > *[data-selectable="true"]')
        .forEach((e) => {
          this.highlightChildElement(e);
        });
    }

    this.updateHistory(target);
  }

  updateHistory(target) {
    // console.log(target);
    let currentElement = target;
    const history = [currentElement];

    for (
      let index = 0;
      currentElement &&
      index < 50 &&
      currentElement.dataset.editorId !== this.rootId;
      index++
    ) {
      currentElement = currentElement.parentElement;
      if (currentElement && currentElement.dataset.selectable === "true") {
        history.push(currentElement);
      }
    }

    if (history && history.length > 1) {
      history.reverse();
    }
    let historyDom = [];
    history.forEach((elem) => {
      historyDom.push(
        `<button type="button" class="section-editor__history-button" data-history-id="${elem.dataset.editorId}" data-action="section-editor#goBack">${elem.dataset.editorType}</button>`
      );
    });
    this.historyTarget.innerHTML = historyDom.join(">");
  }

  // triggered by clicking an item in the "Component History" container
  goBack(e) {
    const id = e.target.dataset.historyId;
    const target = document.querySelector(`[data-editor-id='${id}']`);
    if (target) {
      this.cleanupEditorTarget();
      this.changeEditorTarget(target);
    }
  }

  resizeGridOverlay(overlay) {
    const rootRect = this.rootElement.getBoundingClientRect();
    const rootOffsetLeft = this.rootElement.offsetLeft;
    const rootOffsetTop = this.rootElement.offsetTop;

    const { offsetLeft, offsetTop, offsetHeight, offsetWidth } =
      this.gridOverlayTargetNode;

    overlay.style.left = `${rootRect.left + rootOffsetLeft}px`;
    overlay.style.top = `${rootRect.top + rootOffsetTop}px`;

    overlay.style.height = `${offsetHeight}px`;
    overlay.style.width = `${offsetWidth}px`;
  }

  createGridOverlay(targetGrid) {
    this.gridOverlayTargetNode = targetGrid;

    const gridNode = document.createElement("div");
    gridNode.id = "section-editor-grid-overlay";
    gridNode.classList.add("cs-grid-overlay");
    gridNode.classList.add(`${targetGrid.dataset.editorId}`);

    this.resizeGridOverlay(gridNode);

    const gridColumns = window
      .getComputedStyle(targetGrid)
      .gridTemplateColumns.split(" ")
      .map((col) => {
        return col.replace("px", "");
      });

    const gridRows = window
      .getComputedStyle(targetGrid)
      .gridTemplateRows.split(" ")
      .map((row) => {
        return row.replace("px", "");
      });

    for (let i = 0; i < gridColumns.length; i++) {
      const colWidth = gridColumns[i];
      const gridColumn = document.createElement("div");

      gridColumn.classList.add("cs-grid-overlay__item");
      gridColumn.classList.add("cs-grid-overlay__column");
      gridColumn.style.height = "100%";
      gridColumn.style.width = Number(colWidth) * (i + 1) + "px";
      gridColumn.style.borderRight = "1px dashed #1479bb78";

      gridNode.appendChild(gridColumn);
    }

    for (let i = 0; i < gridRows.length; i++) {
      const rowHeight = gridRows[i];
      const gridRow = document.createElement("div");

      gridRow.classList.add("cs-grid-overlay__item");
      gridRow.classList.add("cs-grid-overlay__row");
      gridRow.style.width = "100%";
      gridRow.style.height = Number(rowHeight) * (i + 1) + "px";
      gridRow.style.borderBottom = "1px dashed #1479bb78";

      gridNode.appendChild(gridRow);
    }

    // gridColumns.forEach()
    // const numberOfItems = gridColumns.length * gridRows.length;
    // // todo: cache the grid items
    // let gridItems = '';
    // for (let index = 0; index < numberOfItems; index++) {
    //   gridItems = gridItems.concat('<div class="cs-grid-overlay__item"></div>');
    // }
    // gridNode.innerHTML = gridItems;
    // debugger

    this.gridOverlay = gridNode;
  }

  // deselectElements(atLevel) {
  //   const elementsAtLevel = this.element.querySelectorAll(`*[data-target="section-editor.level"][data-level="${atLevel}"]`);
  //   if (elementsAtLevel.length > 0) {
  //     elementsAtLevel.forEach((element) => {
  //       element.classList.remove('section-editor__element--highlighted');
  //     });
  //   }
  // }

  dragNewLayoutComponent(e: DragEvent) {
    const target: HTMLElement = e.target as HTMLElement;

    console.log(target.dataset.editorType);
    const data = {
      type: target.dataset.editorType,
      node: "layout",
    };
    e.dataTransfer.setData("text/plain", JSON.stringify(data));

    if (this.currentElement.dataset.dropPlaceholder === "true") {
      this.activateDropPlaceholder(this.currentElement);
    }
  }

  activateDropPlaceholder(placeholderTarget: HTMLElement) {
    if (placeholderTarget) {
      const { left, top, width, height } =
        placeholderTarget.getBoundingClientRect();

      const placeholder = document.createElement("div");
      placeholder.classList.add("section-editor__placeholder");
      placeholder.dataset["target"] = "section-editor.placeholder";
      placeholder.dataset["action"] = [
        "dragenter->section-editor#dragNewComponentEnter",
        "dragleave->section-editor#dragNewComponentLeave",
        "drop->section-editor#itemDrop",
        "dragover->section-editor#dragNewComponentOver",
      ].join(" ");
      placeholder.style.top = `${top + this.previewTarget.scrollTop}px`;
      placeholder.style.left = `${left + this.previewTarget.scrollLeft}px`;
      placeholder.style.height = `${height}px`;
      placeholder.style.width = `${width}px`;

      this.previewTarget.appendChild(placeholder);

      // this.overlays[elem.dataset.editorId] = overlay;

      this.activePlaceholder = placeholder;
    }
  }

  deactivateDropPlaceholder() {
    if (this.activePlaceholder) {
      this.previewTarget.removeChild(this.activePlaceholder);
      this.activePlaceholder = null;
    }
  }

  dragNewComponent(e: DragEvent) {
    const target: HTMLElement = e.target as HTMLElement;

    console.log(target.dataset.editorType);
    const data = {
      type: target.dataset.editorType,
      node: "component",
    };
    e.dataTransfer.setData("text/plain", JSON.stringify(data));

    if (this.currentElement.dataset.dropPlaceholder === "true") {
      this.activateDropPlaceholder(this.currentElement);
    }
  }

  dragNewComponentEnter(e) {
    const dropTarget = e.target;
    dropTarget.classList.add("section-editor__new-component--drag-hover");
    e.preventDefault();
    // console.log('hover');
  }

  dragNewComponentOver(e) {
    e.preventDefault();

    if (this.activePlaceholder) {
      this.activePlaceholder.classList.add(
        "section-editor__placeholder--hover"
      );
    }
  }

  dragNewComponentLeave(e) {
    const dropTarget = e.target;
    dropTarget.classList.remove("section-editor__new-component--drag-hover");
    // console.log('leave');
    if (this.activePlaceholder) {
      this.activePlaceholder.classList.remove(
        "section-editor__placeholder--hover"
      );
    }
  }

  dragNewLayoutComponentEnd(e) {
    this.deactivateDropPlaceholder();
  }

  dragNewComponentEnd(e) {
    this.deactivateDropPlaceholder();
  }

  itemDrop(e: DragEvent) {
    const editorId = this.currentElement.dataset.editorId;

    this.deactivateDropPlaceholder();

    const dropData = JSON.parse(e.dataTransfer.getData("text/plain"));
    const node = dropData.node;

    if (node === "layout") {
      this.addLayoutComponent(dropData.type, editorId);
    } else {
      this.addComponent(dropData.type, editorId);
    }
  }

  addLayoutComponent(type: string, componentId: string) {
    fetch(this.data.get("addLayoutComponentUrl"), {
      method: "post",
      body: JSON.stringify({
        type: type,
        component_id: componentId,
      }),
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": Rails.csrfToken(),
      },
      credentials: "same-origin",
    })
      .then((response) => response.json())
      .then((data) => {
        // console.log(data);
        // window.location.reload();
        Turbo.visit("", { action: "replace" });
      });
  }

  addComponent(type, componentId) {
    fetch(this.data.get("addComponentUrl"), {
      method: "post",
      body: JSON.stringify({
        type: type,
        component_id: componentId,
      }),
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": Rails.csrfToken(),
      },
      credentials: "same-origin",
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        // console.log(data);
        // window.location.reload();
        Turbo.visit("", { action: "replace" });
      });
  }

  exportComponent(e) {
    const nodeId = this.currentElement.dataset.editorId;
    const sectionTemplateId = this.data.get("templateId");
    const exportUrl = `/section_templates/${sectionTemplateId}/section_nodes/${nodeId}/export_component`;

    fetch(exportUrl, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": Rails.csrfToken(),
      },
      credentials: "same-origin",
    })
      .then((response) => response.json())
      .then((r) => {
        // console.log(r);

        // copy response.data to clipboard:
        const el = document.createElement("textarea");
        el.value = r.data;
        el.setAttribute("readonly", "");
        el.style.position = "absolute";
        el.style.left = "-9999px";
        document.body.appendChild(el);
        el.select();
        document.execCommand("copy");
        document.body.removeChild(el);
      });
  }

  deleteComponent(e) {
    const nodeId = this.currentElement.dataset.editorId;
    const sectionTemplateId = this.data.get("templateId");
    const deleteUrl = `/section_templates/${sectionTemplateId}/section_nodes/${nodeId}/delete_component`;

    fetch(deleteUrl, {
      method: "delete",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": Rails.csrfToken(),
      },
      credentials: "same-origin",
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        // console.log(data);
        // window.location.reload();
        Turbo.visit("", { action: "replace" });
      });
  }

  styledView() {
    if (this.currentView !== "styled") {
      this.styledViewTarget.classList.add("section-editor__option--active");
      this.structuredViewTarget.classList.remove(
        "section-editor__option--active"
      );

      this.element
        .querySelectorAll('*[data-selectable="true"]')
        .forEach((elem: HTMLElement) => {
          elem.className = elem.dataset.classWas;
          elem.dataset.classWas = "";
        });

      this.currentView = "styled";
    }
  }

  structuredView() {
    if (this.currentView !== "structured") {
      this.styledViewTarget.classList.remove("section-editor__option--active");
      this.structuredViewTarget.classList.add("section-editor__option--active");

      this.element
        .querySelectorAll('*[data-selectable="true"]')
        .forEach((elem: HTMLElement) => {
          elem.dataset.classWas = elem.className;
          elem.className = `section-editor__structured`;
        });

      this.currentView = "structured";
    }
  }

  stylesSaved(e) {
    let [data, status, xhr] = e.detail;
    const parsedResponse = JSON.parse(xhr.response);

    const newStyles = parsedResponse.styles;
    if (newStyles) {
      const cssContainer = document.querySelector(
        "#section-editor-styles"
      ) as HTMLElement;

      if (ConvertCss) {
        ConvertCss.stringToDom(newStyles, cssContainer);
      } else {
        console.error("ConvertCss not found");
      }
    }

    // button declared in the ButtonController
    // ButtonController:
    //   this.element[this.identifier] = this;
    const button = (this.saveStylesTarget as any).button;

    button.unload();
  }

  // triggered along with picture&text form ajax:success
  componentSaved(e) {
    let [data, status, xhr] = e.detail;
    const parsedResponse = JSON.parse(xhr.response);

    const componentProperties = parsedResponse.component_properties;
    const componentId = parsedResponse.component.id;
    const componentHtml = parsedResponse.component.html;

    if (componentProperties && this.hasComponentPropertiesFormTarget) {
      morphdom(this.componentPropertiesFormTarget, componentProperties, {
        childrenOnly: false,
      });
    }

    if (componentId && componentHtml) {
      const component = this.element.querySelector(
        `[data-editor-id='${componentId}']`
      );
      if (component) {
        morphdom(component, componentHtml, {
          childrenOnly: false,
        });

        // this.highlightCurrentElement(this.element.querySelector(`[data-editor-id='${componentId}']`));
        // this.currentElement.querySelectorAll(':scope > *[data-selectable="true"]').forEach((elem) => {
        //   this.highlightChildElement(elem);
        // });
      } else {
        console.error(
          `component with id 'data-editor-id="${componentId}"' not found`
        );
      }
    }
  }

  // Grid child overlay
  //

  resizeGrid(e) {
    const { mode, target } = e.detail;
    const resizableElement = this.findParentResizableElement(target);
    if (!resizableElement) {
      console.error("No parent resizable element found!");

      return;
    }
    const currentBreakpoint = this.calculateCurrentBreakpoint(resizableElement);

    let data;
    switch (mode) {
      case "bottomRight":
        data = {
          gridColumnEnd: e.detail.gridColumnEnd,
          gridRowEnd: e.detail.gridRowEnd,
          currentBreakpoint,
        };

        break;
      case "topLeft":
        data = {
          gridColumnStart: e.detail.gridColumnStart,
          gridRowStart: e.detail.gridRowStart,
          currentBreakpoint,
        };

        break;
      default:
        console.error(`Unknown mode '${e.detail.mode}'`);
        break;
    }

    const sectionTemplateId = this.data.get("templateId");
    const updateGridUrl = `/section_templates/${sectionTemplateId}/section_nodes/${target.dataset.editorId}/update_grid`;
    this.updateGrid(updateGridUrl, data);
  }

  findParentResizableElement(target: HTMLElement) {
    let parentElement = target.parentElement;
    let resizableElement;

    while (parentElement.tagName !== "BODY" && !resizableElement) {
      if (parentElement.dataset.observeResizes === "") {
        resizableElement = parentElement;
      } else {
        parentElement = parentElement.parentElement;
      }
    }

    return resizableElement;
  }

  calculateCurrentBreakpoint(node: HTMLElement) {
    let currentBreakpoint: string;
    const resizableElementWidth = node.offsetWidth;

    if (resizableElementWidth >= 1400) {
      currentBreakpoint = "XXL";
    } else if (resizableElementWidth >= 1200) {
      currentBreakpoint = "XL";
    } else if (resizableElementWidth >= 992) {
      currentBreakpoint = "LG";
    } else if (resizableElementWidth >= 768) {
      currentBreakpoint = "MD";
    } else if (resizableElementWidth >= 576) {
      currentBreakpoint = "SM";
    } else {
      currentBreakpoint = "XS";
    }

    return currentBreakpoint;
  }

  updateGrid(url: string, data: {}) {
    fetch(url, {
      method: "post",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": Rails.csrfToken(),
      },
      credentials: "same-origin",
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        // console.log(data);
        // window.location.reload();
        Turbo.visit("", { action: "replace" });
      })
      .catch((e) => {
        console.error(e);
      });
  }

  disconnect() {
    this.viewportResize.destroy();
  }
}
