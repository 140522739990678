import { Controller } from "@hotwired/stimulus";

import {
  template
} from 'lodash';

export default class extends Controller {
  static get targets() {
    return ['urlInputContainer', 'urlInput', 'paramSelect', 'paramSelectTemplate', 'paramSelectRadios', 'titleInput'];
  }

  connect() {
    this.urlInputContainerTarget.style.display = 'none';
    this.paramSelectTarget.style.display = 'none';
    this.titleInputTarget.style.display = 'none';

    this.slugOptions = JSON.parse(this.data.get('slugOptions'));
    this.currentSlugOption = null;

    this.radioTemplate = template(this.paramSelectTemplateTarget.innerHTML);
  }

  updateCollection(e) {
    this.paramSelectRadiosTarget.innerHTML = '';
    const optionId = e.target.value;
    const optionName = e.target.querySelector(`option[value="${optionId}"]`).innerText
    const options = this.slugOptions[optionName];

    options.forEach((option) => {
      const newRadio = this.radioTemplate({
        'id': option,
      });
      // debugger
      this.paramSelectRadiosTarget.insertAdjacentHTML('beforeend', newRadio);
    });

    this.urlInputContainerTarget.style.display = 'block';
    this.paramSelectTarget.style.display = 'block';
    this.titleInputTarget.style.display = 'block';
  }

  changeParam(e) {
    const newParam = e.target.value;

    this.element.dataset.newPageUrlSuffix = `/:${newParam}`;

    const changeEvent = new Event('change');
    this.urlInputTarget.dispatchEvent(changeEvent);
  }

  disconnect() {}
}