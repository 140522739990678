// import ConvertCss from "../entrypoints/postcss_frontend";
import { Controller } from "@hotwired/stimulus";
import Rails from "@rails/ujs";
import ConvertCss from "../entrypoints/postcss_frontend";

export default class extends Controller {
  static get targets() {
    return [
      "form",
      "codeInput",
      "iframe",
      "componentIframeHead",
      "componentIframeBody",
      "componentStyles",
      "editor",
      "reloadButton",
    ];
  }

  connect() {
    if (this.hasComponentStylesTarget) {
      const container = this.componentStylesTarget.content.children[0];

      ConvertCss.stringToDom(container.innerText, container, () => {
        if (this.hasIframeTarget) {
          this.iframeTarget.srcdoc = `
            <html>
              <head>
                ${this.componentIframeHeadTarget.innerHTML}
                <script type="module">
                  ${this.codeInputTarget.value}
                  application.register('${this.data.get(
                    "controllerName"
                  )}', ComponentController)
                </script>
              </head>
              <body>
                ${this.componentIframeBodyTarget.innerHTML}
                <style type="text/css">
                  ${this.componentStylesTarget.content.children[0].innerText}
                </style>
              </body>
            </html>`;
        }
      });
    }

  }

  refresh() {
    // this.compile();

    // if (this.hasReloadButtonTarget) {
    //   this.reloadButtonTarget.button.unload();
    // }
  }

  disconnect() {
  }
}
