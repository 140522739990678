import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static get targets() {
    return [];
  }

  connect() {
    this.element[this.identifier] = this;
  }

  disconnect() {}

  load(e) {
    setTimeout(() => {
      this.element.disabled = true;
      this.element.classList.add("button--loading");

      this.buttonContentWas = this.element.innerHTML;

      let loadingHTML = `<svg class="button__spinner" viewBox="0 0 50 50"><circle class="button__spinner-path" cx="25" cy="25" r="20" fill="none" stroke-width="5"></circle></svg>${
        this.data.get("loading-text") || "loading..."
      }`;
      this.element.innerHTML = loadingHTML;
      this.isLoading = true;
    });
  }

  unload() {
    setTimeout(() => {
      if (!this.isLoading) {
        return;
      }

      this.element.disabled = false;
      this.element.classList.remove("button--loading");

      this.element.innerHTML = this.buttonContentWas;
      this.element.focus();
    });
  }
}
