import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["toggle", "content"];

  // viewportResize: ViewportResize;
  // declare readonly previewTarget: HTMLElement;
  // declare readonly viewportSizeTarget: HTMLElement;

  connect() {
    // contentTarget.
    this.collapsed = this.data.get("collapsed") === "true";

    if (this.collapsed) {
      this.hide();
    } else {
      this.show();
    }
  }

  toggle() {
    if (this.collapsed) {
      this.show();
    } else {
      this.hide();
    }
  }

  show() {
    this.contentTarget.style.display = "block";
    this.toggleTarget.innerHTML = this.data.get("uncollapsedText");

    this.collapsed = false;
    this.data.set("collapsed", "false");
  }

  hide() {
    this.contentTarget.style.display = "none";
    this.toggleTarget.innerHTML = this.data.get("collapsedText");

    this.collapsed = true;
    this.data.set("collapsed", "true");
  }

  disconnect() {
    if (this.hasToggleTarget && this.hasContentTarget) {
      this.hide();
    }
  }
}
