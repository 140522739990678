import ConvertCss from "../entrypoints/postcss_frontend";
import { Controller } from "@hotwired/stimulus";
export default class extends Controller {
  connect() {
    console.log("[editor preview styles controller] connected");

    ConvertCss.stringToDom(this.element.innerText, this.element);
  }

  disconnect() {}
}
